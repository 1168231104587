<template>
  <div>
    <h1>{{ groupName }}</h1>

    <template v-if="boardMembers.length">
      <h2>Bestuursleden</h2>

      <table>
        <tr v-for="member in boardMembers" :key="member.id">
          <td>{{ member.role }}</td>
          <td>{{ member.user.firstName }}</td>
          <td>{{ member.user.lastName }}</td>
          <td>{{ member.user.phonenumber }}</td>
          <td>{{ member.user.email }}</td>
        </tr>
      </table>
    </template>

    <template v-if="members.length">
      <h2>Leden</h2>
      <UserList :users="members" />
    </template>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';
import { useQuery } from 'villus';
import UserList from '../components/UserList.vue';

export default defineComponent({
  components: { UserList },

  name: 'GroupDetails',

  props: {
    id: String,
  },

  setup(props) {
    const Group = `
    query {
      group(id: ${props.id}) {
        id
        name

        members(first:300) {
          edges {
            node {
              id
              firstName
              lastName
              email
              phonenumber
              town
              groups {
                name
              }
            }
          }
        }

        boardMembers(first:50) {
          edges {
            node {
              id
              role
              user {
                email
                firstName
                lastName
                phonenumber
              }
            }
          }
        }
      }
    }
    `;

    const { data: groupResult } = useQuery(Group);
    const members = ref([]);
    const boardMembers = ref([]);
    const groupName = ref('');

    watch(groupResult, ({ group }) => {
      members.value = group.members.edges.map((edge) => edge.node);
      boardMembers.value = group.boardMembers.edges.map((edge) => edge.node);
      groupName.value = group.name;
    });

    return {
      boardMembers,
      members,
      groupName,
    };
  },
});
</script>

<style lang="scss" scoped>
table {
  margin-bottom: 32px;
}
</style>
